import React from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Header from './header/header';
import CustomContact from './contact/contact';
import Box from '@mui/material/Box';
import Usp from './usp/usp';
import ContactV2 from './contact/contact_v2';
import Preorder from './pre_order/pre_order';
import { Container } from '@mui/material';
import { Custom } from 'layouts';
import Hero from 'views/landingPages/DesignAgency/components/Contact/Contact';
import How from './how/how';
import HeaderV2 from './header/header_v2'
import Functionalities from './functionalities/functionalities';
import { useRef } from 'react';
import roundedFixedBtn from '../css_files/fixedbutton.css';
import { Footer } from 'layouts/Main/components';


const Homepage = ({ themeMode = 'light' }) => {
  const paragraphRef = useRef(null);
  const theme = useTheme();
  return (
    <Box>
      <HeaderV2 themeMode='light'>
      </HeaderV2>
      <How ref={paragraphRef}/>
      <Functionalities>
      </Functionalities>
      <Usp themeMode={themeMode}>
      </Usp>
      <div ref={paragraphRef}>
      <Preorder>
      </Preorder>
      </div>
      <Box bgcolor={theme.palette.primary.main}>
        <Container justifyContent={'center'} alignItems={'center'} >
          <ContactV2/>
        </Container>
      </Box>
    </Box>
  );
};

Header.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default Homepage;
