import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const Functionalities = () => {
    const theme = useTheme();
  
    return (
        <div>
        <Grid container marginBottom={4} justifyContent={'center'} alignItems={'center'} direction={'column'} display={'flex'}>
        <Grid item mb={2} ml={3} mr={3} mt={3}>
          <Typography
            variant={'h3'}
            align={'center'}
            fontWeight={700}
          >
            Hoe werkt ShadowGuard in de praktijk?
          </Typography>
          </Grid>
          <Grid item ml={3} mr={3}>
          <Typography
            variant={'h6'}
            component={'p'}
            color={'textSecondary'}
            align={'center'}
          >
            Het gebruik van uw ShadowGuard systeem is zeer eenvoudig.
          </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems={'flex-start'} justifyContent={'center'}>
          {[
            {
              title: 'Camera installeren',
              subtitle:
                'U krijgt uw ShadowGuard systeem kant en klaar geleverd. Deze kunt u eenvoudig monteren op uw schaduwbord.',
            },
            {
              title: 'Geautomatiseerde analyse',
              subtitle:
                'De beelden worden aan de hand van artificiële intelligentie automatisch verwerkt zonder dat u iets hoeft te doen. Daarna kunt u aan de hand van handige statistieken in één oogopslag zien welke schaduwborden correct gebruikt worden.',
            },
            {
              title: 'Sit back and relax',
              subtitle:
                'De rapporten gegenereerd door ShadowGuard kunnen gebruikt worden tijdens opleidingen. Ervaar hoe uw werkvloer transformeert naar een nette en veilige werkomgeving.',
            },
          ].map((item, i) => (
            <Grid item xs={12} sm={6} md={3} key={i} data-aos={'fade-up'} maxWidth={'90%'}>
              <Box display={'flex'} flexDirection={'column'}>
                <Box display={'flex'} alignItems={'center'} marginBottom={1}>
                  <Box
                    borderRadius={'100%'}
                    bgcolor={'#000080'}
                    marginRight={2}
                    width={30}
                    height={30}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Box
                      component={Typography}
                      variant={'h7'}
                      fontWeight={600}
                      color={theme.palette.common.white}
                    >
                      {i + 1}
                    </Box>
                  </Box>
                  <Typography variant={'h6'} gutterBottom fontWeight={500}>
                    {item.title}
                  </Typography>
                </Box>
                <Typography color="text.secondary">{item.subtitle}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };
  
  export default Functionalities;