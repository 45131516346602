import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ReactPlayer from 'react-player';
import titles from 'css_files/titles.css'

import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import { useRef } from 'react';

const scrollDown = (ref) => {
  window.scrollTo({
    top: ref.current.offsetTop,
    behavior: 'smooth',
  });
};

function How (paragraphRef) {
  var element = document.getElementById("interesse");
  const theme = useTheme();
  /*
  return (
    <Box mt={1} display={'flex'} justifyContent={'center'} alignItems={'center'} marginBottom={10}>
    <Grid container flexDirection={'row'} alignItems={'center'} justifyContent={'center'} spacing={8} width={'95%'}>
    <Grid item xs={12} md={7}  mb={0} alignItems={'center'} justifyContent={'center'} flexGrow={1}>
    <Box
          component={Typography}
          sx={{ fontWeight: 700 }}
          variant={'h3'}
          color={'#10293F'}
          fontFamily={'"Hammersmith One", sans-serif'}
          gutterBottom
          align={'center'}
          mb={5}
        >
          Wat doet Shadowguard?
        </Box>
    <ReactPlayer
      config={{ file: { attributes: { controlsList: 'nodownload' } } }}
      url="videos/ShadowGuard.MP4"
      controls={true}
      playing={false}
      width={'100%'}
      height={'100%'}
    />
    </Grid>
      <Grid item xs={12} md={5}>
        <Grid container spacing={4} flexDirection={'column'}>
        {[
          {
            title: 'Camera installeren',
            subtitle:
              'U krijgt uw ShadowGuard systeem kant en klaar geleverd. Deze kunt u eenvoudig monteren op uw schaduwbord.',
          },
          {
            title: 'Geautomatiseerde analyses',
            subtitle:
              'De beelden worden aan de hand van artificiële intelligentie automatisch verwerkt zonder dat u iets hoeft te doen. Daarna kunt u aan de hand van handige statistieken in één oogopslag zien welke schaduwborden correct gebruikt worden.',
          },
          {
            title: 'Sit back and relax',
            subtitle:
              'Ervaar hoe uw werkvloer transformeert naar een nette en veilige werkomgeving.',
          },
        ].map((item, i) => (
          <Grid item xs={12} key={i} data-aos={'fade-up'}>
            <Box display={'flex'} flexDirection={'column'}>
              <Box display={'flex'} alignItems={'center'} marginBottom={1}>
                <Box
                  borderRadius={'100%'}
                  bgcolor={theme.palette.secondary.main}
                  marginRight={2}
                  width={40}
                  height={40}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Box
                    component={Typography}
                    variant={'h6'}
                    fontWeight={600}
                    color={theme.palette.common.white}
                  >
                    {i + 1}
                  </Box>
                </Box>
                <Typography variant={'h6'} gutterBottom fontWeight={500}>
                  {item.title}
                </Typography>
              </Box>
              <Typography color="text.secondary">{item.subtitle}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      </Grid>
    </Grid>
    </Box>
  );*/

  return (
    <Box>
    <Box
    component={Typography}
    sx={{ fontWeight: 700 }}
    variant={'h3'}
    color={'#10293F'}
    fontFamily={'"Hammersmith One", sans-serif'}
    gutterBottom
    align={'center'}
    mb={5}
  >
    Digitale opvolging van schaduwborden
    </Box>
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} marginBottom={5}>
    <Grid container flexDirection={'row'} alignItems={'center'} justifyContent={'center'} spacing={8} width={'95%'}>
    <Grid item xs={12} md={7} alignItems={'center'} justifyContent={'center'} flexGrow={1}>
    <ReactPlayer
      /*config={{ file: { attributes: { controlsList: 'nodownload' } } }}
      url={`${process.env.PUBLIC_URL}/videos/ShadowGuard.mp4`}*/
      url={"https://www.youtube.com/watch?v=dPOXuDkerj8"}
      controls={true}
      playing={false}
      width={'100%'}
    />
    </Grid>
    <Grid item spacing={1}>
      <Typography fontFamily={'"Hammersmith One", sans-serif'} variant='h5' align='center' mb={5}>
      Voordelen voor uw bedrijf
      </Typography>
                {[
                  'Veilige werkomgeving',
                  'Orde en netheid',
                  'Vermijden onnodige stress',
                  'Voorkomen verlies van materialen',
                  'Tijdsbesparing ',
                ].map((item, i) => (
                  <Grid item xs={12} key={i} mb={3}>
                    <Box
                      component={ListItem}
                      disableGutters
                      width={'auto'}
                      padding={0}
                    >
                      <Box
                        component={ListItemAvatar}
                        minWidth={'auto !important'}
                        marginRight={2}
                      >
                        <Box
                          component={Avatar}
                          bgcolor={'#000080'}
                          width={30}
                          height={30}
                        >
                          <svg
                            width={18}
                            height={18}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </Box>
                      </Box>
                      <ListItemText primary={item} />
                    </Box>
                  </Grid>
                ))}
    <Grid item mt={6}>
    <Button
      sx={{ height: 54 }}
      variant="contained"
      color="primary"
      size="medium"
      fullWidth
      onClick={() =>
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        })
      }
    >
    <Typography>
      Interesse?
    </Typography>
    </Button>
    </Grid>
    </Grid>

    </Grid>
    </Box>
    </Box>
  );

};

export default How;
