/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Webbee from 'svg/logos/Webbee';
import Shadowguard from 'svg/custom_images/logo_shadowguard_web.png'

const Headline = () => {
  const theme = useTheme();
  return (
      <Grid container spacing={4} flexDirection={'row'} justifyContent={'space-between'} display={'flex'} alignItems={'center'} verticalAlign={'middle'}>
        <Grid item>
        <Box marginBottom={2}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              color: theme.palette.common.white,
            }}
          >
            Shadow in the
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h2"
            color={'primary'}
            sx={{
              fontWeight: 700,
            }}
          >
            Picture.
          </Typography>
        </Box>
        </Grid>
        <Grid item justifyContent={'flex-end'} alignItems={'center'} verticalAlign={'middle'}>
          <Box alignItems={'center'}>
            <img alt="logo Shadowguard" src={Shadowguard} width={'50%'} ></img>
          </Box>
        </Grid>
      </Grid>
  );
};

export default Headline;
