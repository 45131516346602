import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import { Container } from '@mui/material';



const ContactV2 = () => {
  const theme = useTheme();

  const MapV2 = () => {
    return(
    <Box
    padding={{ xs: 3, sm: 6 }}
    width={'100%'}
    component={Card}
    borderRadius={2}
    boxShadow={4}
    marginBottom={4}>
        <Box>
            <Box
              component={'iframe'}
              borderRadius={2}
              width="100%"
              height="100%"
              minHeight={300}
              frameBorder="0"
              title="map"
              marginHeight="0"
              marginWidth="0"
              scrolling="no"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2514.5695048949074!2d3.1586779762840247!3d50.93168005259845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c349feb2d0f9e5%3A0x515cb0486c5ed9e6!2sOnesta%20Consultancy!5e0!3m2!1snl!2sbe!4v1699305117840!5m2!1snl!2sbe"
            />
          </Box>
          <Grid container spacing={2} mt={3}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant={'body1'}
                gutterBottom
                sx={{ fontWeight: 'medium' }}
              >
                Telefoon:
              </Typography>
              <Typography variant={'subtitle1'}>+32 471 77 54 78</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant={'body1'}
                gutterBottom
                sx={{ fontWeight: 'medium' }}
              >
                Email:
              </Typography>
              <Link href="mailto:info@shadowguard.be" >
              <Typography variant={'subtitle1'}>info@shadowguard.be</Typography>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant={'body1'}
                gutterBottom
                sx={{ fontWeight: 'medium' }}
              >
                Adres:
              </Typography>
              <Typography variant={'subtitle1'}>
              Izegemsestraat 60A, 8800 Roeselare
              </Typography>
              <Typography variant={'subtitle1'}>
              Belgium
              </Typography>
            </Grid>
    
            <Grid item xs={12} sm={6}>
              <Typography
                variant={'body1'}
                gutterBottom
                sx={{ fontWeight: 'medium' }}
              >
                Volg ons op sociale media:
              </Typography>
              <Grid container direction='row' spacing={2} flexGrow={1}>
                <Grid item >
              <Link href="https://www.linkedin.com/company/shadowguard" target="_blank">
              <LinkedInIcon sx={{color:'#0077B5'}} fontSize='large'>
              </LinkedInIcon>
              </Link>
              </Grid>
              <Grid item>
              <Link href="https://www.facebook.com/ShadowGuardBE" target="_blank" >
              <FacebookIcon sx={{color:'#1877F2'}} fontSize='large'>
              </FacebookIcon>
              </Link>
              </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
    )
    }

  const GridItemHeadlineBlock = () => (
    <Box>
      <Typography
        variant="h2"
        align="left"
        fontFamily={'"Hammersmith One", sans-serif'}
        gutterBottom
        sx={{
          color: theme.palette.common.white,
          fontWeight: 900,
        }}
      >
        Contacteer ons!
      </Typography>
      <Box marginBottom={4}>
        <Typography
          variant="h6"
          component="p"
          fontFamily={'"Hammersmith One", sans-serif'}
          sx={{
            color: theme.palette.common.white,
            fontWeight: 400,
          }}
        >
          We horen graag hoe we jullie kunnen helpen.
        </Typography>
      </Box>
    </Box>
  );
  /*
  const GridItemFormBlock = () => (
    <Box
      padding={{ xs: 3, sm: 6 }}
      width={'100%'}
      component={Card}
      borderRadius={2}
      boxShadow={4}
    >
      <form noValidate autoComplete="off">
        <Box display="flex" flexDirection={'column'}>
          <Box marginBottom={2}>
            <TextField
              sx={{ height: 54 }}
              label="Full name"
              variant="outlined"
              color="primary"
              fullWidth
            />
          </Box>
          <Box marginBottom={2}>
            <TextField
              sx={{ height: 54 }}
              label="Email"
              type="email"
              variant="outlined"
              color="primary"
              fullWidth
            />
          </Box>
          <Box marginBottom={2}>
            <TextField
              label="Message"
              type="text"
              variant="outlined"
              color="primary"
              fullWidth
              multiline
              rows={6}
            />
          </Box>
          <Box>
            <Button
              sx={{ height: 54 }}
              variant="contained"
              color="primary"
              fullWidth
            >
              Contact
            </Button>
          </Box>
          <Box marginY={4} marginX={{ xs: -3, sm: -6 }}>
            <Divider />
          </Box>
          <Box>
            <Typography component="p" variant="body2" align="left">
              By sending a message you agree to our{' '}
              <Box
                component="a"
                href=""
                color={theme.palette.text.primary}
                fontWeight={'700'}
              >
                Privacy Policy
              </Box>
              ,{' '}
              <Box
                component="a"
                href=""
                color={theme.palette.text.primary}
                fontWeight={'700'}
              >
                Data Policy
              </Box>{' '}
              and{' '}
              <Box
                component="a"
                href=""
                color={theme.palette.text.primary}
                fontWeight={'700'}
              >
                Cookie Policy
              </Box>
              .
            </Typography>
          </Box>
        </Box>
      </form>
    </Box>
  );*/


  return (
    <Container justifyContent={'center'} alignItems={'center'} display={'flex'}>
    <Box>
      <Grid container spacing={4} justifyContent={'center'} alignItems={'center'} display={'flex'}>
        <Grid item xs={12} md={4} mb={-4} justifyContent={'center'} alignItems={'center'} display={'flex'}>
          <Box width={1} height="100%" display="flex" alignItems="center">
            <GridItemHeadlineBlock />
          </Box>
        </Grid>
        <Grid item xs={12} md={8} alignItems={'center'}>
          <Box width={1} height="100%" display="flex" alignItems="center">
            <MapV2/>
          </Box>
        </Grid>
      </Grid>
    </Box>
    </Container>
  );
};

ContactV2.propTypes = {
    themeMode: PropTypes.string.isRequired,
  };

export default ContactV2;