import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from 'common/Container';
import Headline from './headline';
import shadowboard from '../../svg/custom_images/shadowboard.png'
import headerImage from '../../svg/custom_images/header_image.jpg'
import imageheader from '../../css_files/header.css'
import ShadowGuardLogo from '../../svg/custom_images/logo_white.png'
import roundedheader from '../../css_files/borderheader.css'
import Grid from '@mui/material/Grid';


const HeaderV2 = ({ themeMode = 'light' }) => {
    const theme = useTheme();
  
    return (
      <Box className='borderheader' lineHeight={'0em'}>
        <Grid container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        display='flex'
        pt={3}
        lineHeight={0}>
        <Grid item alignItems={'center'} justifyContent={'center'} display={'flex'} xs={6} lineHeight={0}>
        <img src={ShadowGuardLogo} width={'50%'} lineHeight={0}></img>
        </Grid>
        </Grid>
        <svg id="visual" viewBox="0 0 900 100" width="100%" height="auto" lineHeight='0' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"><path d="M0 51L30 47.7C60 44.3 120 37.7 180 34C240 30.3 300 29.7 360 31C420 32.3 480 35.7 540 41.8C600 48 660 57 720 62.5C780 68 840 70 870 71L900 72L900 101L870 101C840 101 780 101 720 101C660 101 600 101 540 101C480 101 420 101 360 101C300 101 240 101 180 101C120 101 60 101 30 101L0 101Z" fill="#FFF" stroke-linecap="round" stroke-linejoin="miter"></path></svg>
      </Box>
    );
    /*
    return (
      <Box>
        <img src={headerImage} className='imageheader'  ></img>
      </Box>
    );*/
/*
    return (
      <Box>
        <Box
          position={'relative'}
          sx={{
            backgroundImage:
            `url(${headerImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            maxWidth: '100%',
            height: 'auto'
          }}
        >
          <Container zIndex={3} position={'relative'}>
            <Container
              marginLeft={'0 !important'}
              maxWidth={{ md: '95% !important' }}
            >
              <Headline />
            </Container>
          </Container>
        </Box>
      </Box>
    );*/

  };
  
  HeaderV2.propTypes = {
    themeMode: PropTypes.string.isRequired,
  };
  
  export default HeaderV2;
  