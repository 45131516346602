/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import checkmark from '../../css_files/checkmark.css'

function postNewInterestedPerson(values,setConfirmed) {
    fetch('https://shadowguard.be/api/presave', {
      method: "POST",
      body: JSON.stringify({
        values
      }),
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": '*'
      }
    })
    }


function Preorder(ref) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [allValues, setAllValues] = React.useState({
    first_name: '',
    last_name: '',
    email: '',
    company:'',
    comment:''
  });

  const [missingValue,setMissingValue] = React.useState(true);
  const changeHandler = e => {
      setAllValues({...allValues, [e.target.name]: e.target.value})
      console.log(allValues)
  }

  const [confirmed,setConfirmed] = React.useState(false);
  const handleNext = e => {
    e.preventDefault();
    setConfirmed(true)
    console.log(allValues);
    postNewInterestedPerson(allValues,setConfirmed)
}

switch(confirmed) {
    case false:
        return (
          <div id='interesse'>
            <Box bgcolor={theme.palette.alternate.main}>
            <Container
            sx={{justifyContent:'center',alignItems:'center',display:'flex',textAlign:'center', flexDirection:'column', mt:5,mb:5}}
            >
                <Box
                  component={Typography}
                  sx={{ fontWeight: 700 }}
                  color={'#10293F'}
                  fontFamily={'"Hammersmith One", sans-serif'}
                  variant={'h3'}
                  gutterBottom
                  align={'center'}
                  mb={4}
                  mt={5}
                >
                  Interesse?
                </Box>
                <Box
                  component={Typography}
                  gutterBottom
                  align={'center'}
                  mb={4}
                >
                  Laat uw gegevens hier achter om op de hoogte te blijven van de lancering van de eerste ShadowGuard systemen.
                </Box>
              <Box
                padding={{ xs: 3, sm: 6 }}
                component={Card}
                borderRadius={2}
                boxShadow={4}
                marginBottom={4}
                align={'center'}
                width={'85%'}
              >
              <form autoComplete="off"
                onSubmit={e => {
                            handleNext(e);
                }}>
                  <Grid container spacing={isMd ? 4 : 2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={{ height: 54 }}
                        label="Voornaam"
                        name='first_name'
                        variant="outlined"
                        color="primary"
                        size="medium"
                        onChange={changeHandler}
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={{ height: 54 }}
                        label="Achternaam"
                        name='last_name'
                        variant="outlined"
                        color="primary"
                        size="medium"
                        onChange={changeHandler}
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={{ height: 54 }}
                        label="Email"
                        name='email'
                        type="email"
                        variant="outlined"
                        color="primary"
                        size="medium"
                        onChange={changeHandler}
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={{ height: 54 }}
                        label="Bedrijf"
                        name='company'
                        variant="outlined"
                        color="primary"
                        size="medium"
                        onChange={changeHandler}
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        multiline
                        rows={6}
                        name='comment'
                        variant="outlined"
                        color="primary"
                        onChange={changeHandler}
                        size="medium"
                        placeholder='Opmerkingen, vragen, ... (optioneel)'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                    <Box>
                      <Typography component="p" variant="body2" align="center">
                        Indien u klikt op "verzenden" gaat u akkoord met ons {' '}
                        <Box
                          component="a"
                          href="https://onesta.be/privacybeleid/"
                          target="_blank"
                          color={theme.palette.text.primary}
                          fontWeight={'700'}
                        >
                          privacybeleid
                        </Box>
                        .
                      </Typography>
                    </Box>
                  </Grid>
                    <Grid item xs={12}>
                      <Button
                        sx={{ height: 54 }}
                        variant="contained"
                        color="primary"
                        size="medium"
                        fullWidth
                        type='submit'
                      >
                      <Typography>
                        Verzenden
                      </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Container>
            </Box>
            </div>
          );
    case true:
        return (
          <div id='interesse'>
          <Box bgcolor={theme.palette.alternate.main}>
            <Container
            sx={{justifyContent:'center',alignItems:'center',display:'flex',textAlign:'center', flexDirection:'column', mt:5,mb:5}}
            >
                <Box
                  component={Typography}
                  sx={{ fontWeight: 700 }}
                  color={'#10293F'}
                  fontFamily={'"Hammersmith One", sans-serif'}
                  variant={'h3'}
                  gutterBottom
                  align={'center'}
                  mb={4}
                  mt={5}
                >
                  Interesse?
                </Box>
                <Box
                  component={Typography}
                  gutterBottom
                  align={'center'}
                  mb={4}
                >
                  Laat uw gegevens hier achter om op de hoogte te blijven van de lancering van de eerste ShadowGuard systemen.
                </Box>
              <Box
                padding={{ xs: 3, sm: 6 }}
                component={Card}
                borderRadius={2}
                boxShadow={4}
                marginBottom={4}
                width={'85%'}
                align={'center'}
              >
                <form autoComplete="off"
                onSubmit={e => {
                            handleNext(e);
                }}>
                  <Grid container spacing={isMd ? 4 : 2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={{ height: 54 }}
                        label="Voornaam"
                        name='first_name'
                        variant="outlined"
                        color="primary"
                        size="medium"
                        onChange={changeHandler}
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={{ height: 54 }}
                        label="Achternaam"
                        name='last_name'
                        variant="outlined"
                        color="primary"
                        size="medium"
                        onChange={changeHandler}
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={{ height: 54 }}
                        label="Email"
                        name='email'
                        type="email"
                        variant="outlined"
                        color="primary"
                        size="medium"
                        onChange={changeHandler}
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={{ height: 54 }}
                        label="Bedrijf"
                        name='company'
                        variant="outlined"
                        color="primary"
                        size="medium"
                        onChange={changeHandler}
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Opmerkingen"
                        multiline
                        rows={6}
                        variant="outlined"
                        color="primary"
                        onChange={changeHandler}
                        size="medium"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{color:'primary'}}>
                        Uw gegevens werden succesvol geregistreerd! 🎉
                      </Typography>
                      <div class="success-animation" width='50%'>
                      <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Container>
            </Box>
            </div>
          );
}
};

export default Preorder;
