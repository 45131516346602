import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import WebbeeLogo from 'svg/logos/Webbee';
import Onesta from '../../../../svg/custom_images/onesta.png'

const Footer = () => (
  <Grid container spacing={2} alignItems={'center'} justifyContent={'center'} display={'flex'}>
      <Typography
        align={'center'}
        variant={'subtitle1'}
        color="textSecondary"
        component={'p'}
      >
        Powered by Onesta Consultancy
      </Typography>
  <Grid item xs={12} alignItems={'center'} justifyContent={'center'} display={'flex'}>
    <Link href='https://onesta.be' target="_blank" alignItems={'center'} justifyContent={'center'} display={'flex'}>
    <img src={Onesta} alt='Logo_Onesta' width={163} height={100} align={'center'}/> 
    </Link>
  </Grid>
    <Grid item xs={12}>
      <Typography
        align={'center'}
        color="textSecondary"
        fontSize={'small'}
      >
        &copy; ShadowGuard 2023. All rights reserved
      </Typography>
    </Grid>
  </Grid>
);

export default Footer;
