import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';

const Usp = ({ themeMode = 'light' }) => {
  
  return (
    <Box marginLeft={2} marginRight={2} marginTop={8} >
      <Box marginBottom={4}>
        <Box
          component={Typography}
          sx={{ fontWeight: 700 }}
          variant={'h3'}
          gutterBottom
          align={'center'}
          color={'#10293F'}
          fontFamily={'"Hammersmith One", sans-serif'}
        >
          Waarom kiezen voor ShadowGuard?
        </Box>
      </Box>
      <Grid container spacing={4} alignItems={'flex-start'} justifyContent={'center'}>
        {[
          {
            title: 'Compatibiliteit',
            subtitle:
              'Onze oplossing werkt met alle schaduwborden. Groot of klein, zwart of wit. We got you!',
            icon: (
              <svg
                width={24}
                height={24}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill='#00A676'
              >
                <path
                  fillRule="evenodd"
                  d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                  clipRule="evenodd"
                />
              </svg>
            ),
            color: colors.teal,
          },
          {
            title: 'Ondersteuning',
            subtitle:
            <div>Wens je verdere ondersteuning bij de follow-up of implementatie van veiligheids- en kwaliteitssystemen? Geen zorgen, onze partner  <a href='https://onesta.be' target="_blank" style={{ textDecoration: 'none', color:'#305077',fontWeight:'bold' }}>Onesta</a> ontzorgt je van begin tot eind.</div>,
            icon: (

            <svg 
            width={24}
            height={24}
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 24 24" 
            fill="#00A676" 
            class="w-5 h-5"
            >
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
            </svg>
            ),
            color: colors.teal,
          },
          {
            title: 'Flexibiliteit',
            subtitle:
              'Krijg je uw rapporten het liefst via mail of integreer je ze het liefst in jullie bestaande dashboards via onze API? Aan u de keuze. ',
            icon: (
              <svg 
              width={24}
              height={24}
              xmlns="http://www.w3.org/2000/svg" 
              fill="none" viewBox="0 0 24 24" 
              stroke-width="1.5" stroke="#00A676" 
              class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
            </svg>
            

            ),
            color: colors.teal,
          },
        ].map((item, i) => (
          <Grid item xs={12} md={3} sm={6} key={i} mb={2} maxWidth={'90%'}>
            <Box width={1} height={'100%'} data-aos={'fade-up'}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Box
                  component={Avatar}
                  variant="rounded"
                  width={60}
                  height={60}
                  marginBottom={2}
                  bgcolor={item.color[themeMode === 'light' ? 50 : 100]}
                  color={item.color[themeMode === 'light' ? 500 : 700]}
                >
                  {item.icon}
                </Box>
                <Typography
                  variant={'h6'}
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                  align={'center'}
                >
                  {item.title}
                </Typography>
              </Box>
            </Box>
            <Box textAlign='justify'>
            <Typography color="textSecondary">
                  {item.subtitle}
                </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

Usp.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default Usp;
