import React from 'react';
import Contact from 'custom_components/contact/contact';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';
import { BrowserRouter } from 'react-router-dom/dist';
import Routes from 'Routes';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
